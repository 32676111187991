.code-block-container {
	position: relative;
	display: grid;
	grid-template-columns: 2.5rem 1fr;
	padding: 1rem;
	background-color: var(--nord0);
	color: var(--nord6);
	border: 1px solid var(--nord3);
	border-radius: 0.5rem;
	font-size: 12px;
	min-height: 5rem;
	height: fit-content;
}

.line-numbers {
	font-family: "Courier New", Courier, monospace;
	font-size: 14px;
}

.code-block {
	overflow-x: scroll;
}

.toolbar {
	position: absolute;
	top: 1rem;
	right: 1rem;
}

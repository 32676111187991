.header {
	color: #fff;
	padding: 1rem 5vw;
}

.logo-container {
	width: 3.5rem;
	height: fit-content;
}

.logo-container img {
	width: 100%;
	height: auto;
}

.hex-insights {
	font-family: sans-serif;
	font-size: 1.3rem;
}

html {
	--srsl__syntax-highlight---token-assignment: var(--nord9);
	--srsl__syntax-highlight---token-at-sign: var(--nord11);
	--srsl__syntax-highlight---token-attribute-name: var(--nord12);
	--srsl__syntax-highlight---token-edge-arrow: var(--nord10);
	--srsl__syntax-highlight---token-escaped: var(--nord13);
	--srsl__syntax-highlight---token-field-name: var(--nord13);
	--srsl__syntax-highlight---token-field-type: var(--nord7);
	--srsl__syntax-highlight---token-field-type-value: var(--nord4);
	--srsl__syntax-highlight---token-keyword: var(--nord10);
	--srsl__syntax-highlight---line-number: var(--nord3);
	--srsl__syntax-highlight---line-number-with-warning: var(--nord11);
	--srsl__syntax-highlight---token-meta-tag: var(--nord9);
	--srsl__syntax-highlight---token-meta-tag-value: var(--nord7);
	--srsl__syntax-highlight---token-model-name: var(--nord8);
	--srsl__syntax-highlight---token-new-line: var(--nord6);
	--srsl__syntax-highlight---token-property-value: var(--nord4);
	--srsl__syntax-highlight---token-punctuation: var(--nord9);
	--srsl__syntax-highlight---token-quote: var(--nord4);
	--srsl__syntax-highlight---token-separator: var(--nord3);
	--srsl__syntax-highlight---token-string-literal: var(--nord14);
	--srsl__syntax-highlight---token-code-block: var(--nord10);
	--srsl__syntax-highlight---token-number-literal: var(--nord15);
	--srsl__syntax-highlight---warning-underline: var(--nord11);
}

.footer {
	background-color: var(--nord1);

	padding: 3rem 2rem;
}

.footer,
.footer a {
	color: var(--general---color);
}

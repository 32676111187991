.checked .clipboard-icon {
	fill: transparent;
	stroke: var(--nord4);
	animation-duration: 0.8s;
	animation-name: green-check;
}

@keyframes green-check {
	0% {
		fill: green;
		stroke: green;
	}

	100% {
		fill: transparent;
		stroke: var(--nord4);
	}
}

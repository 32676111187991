html {
	/* General */
	--general---color: var(--nord6);
	--general---background-color: var(--nord0);
	--general---background-color--semi-transparent: #2e3440bb;
	color: var(--general---color);
	background-color: var(--general---background-color);

	--heading--level-1---margin: 0 0 1rem;
	--heading--level-2---margin: 0 0 1rem;

	--button--link---color: var(--nord3);
	--button--link--hover-focus---color: var(--nord9);

	--success-color: var(--nord14);
	--warning-color: var(--nord13);
	--danger-color: var(--nord11);
	/* darker nord11 */
	--danger-dark-color: #892f38;

	--search-drop-down__option---border-color: var(--input---border-color);
	--search-drop-down__options-drop-down---background-color: var(--nord2);
	--search-drop-down__options-drop-down---border-color: var(--nord3);
	--search-drop-down__option--keyboard-hover---background-color: var(--nord3);

	--select-field__select-input---border-color: var(--input---border-color);
	--select-field__select-input--focused---border-color: var(--input--focused---border-color);

	--button--primary---color: var(--nord2);
	--button--primary---background-color: var(--nord8);
	--button--primary---border-color: var(--nord8);

	--button--primary--hover-focus---color: var(--nord4);
	--button--primary--hover-focus---background-color: var(--nord9);
	--button--primary--hover-focus---border-color: var(--nord4);

	--button--primary--disabled---color: var(--nord6);
	--button--primary--disabled---background-color: var(--nord0);
	--button--primary--disabled---border-color: var(--nord4);

	--button--secondary---color: var(--nord4);
	--button--secondary---background-color: var(--nord3);
	--button--secondary---border-color: var(--nord3);

	--button--secondary--hover-focus---color: var(--nord0);
	--button--secondary--hover-focus---background-color: var(--nord4);
	--button--secondary--hover-focus---border-color: var(--nord4);

	--button--secondary--disabled---color: var(--nord0);
	--button--secondary--disabled---background-color: var(--nord1);
	--button--secondary--disabled---border-color: var(--nord1);

	--button--tertiary---color: var(--nord4);
	--button--tertiary---background-color: var(--nord0);
	--button--tertiary---border-color: var(--nord3);

	--button--tertiary--hover-focus---color: var(--nord5);
	--button--tertiary--hover-focus---background-color: var(--nord3);
	--button--tertiary--hover-focus---border-color: var(--nord4);

	--button--tertiary--disabled---color: var(--nord6);
	--button--tertiary--disabled---background-color: var(--nord0);
	--button--tertiary--disabled---border-color: var(--nord4);

	--button--danger---background-color: var(--danger-dark-color);
	--button--danger---border-color: var(--danger-dark-color);

	--default---box-shadow: 2px 2px 4px var(--nord0), 0px 0px 4px var(--nord0);
	--tooltip__stem---box-shadow: var(--default---box-shadow);
	--tooltip__body---background-color: var(--nord2);
	--tooltip---background-color: var(--nord2);

	--modal__background---background-color: #4c566aa0; /* nord3 with transparency */
	--modal---background-color: var(--nord0);
	--modal__header---color: var(--nord6);
	--modal__header---background-color: var(--nord2);
	--modal__body---color: var(--nord5);
	--modal__body---background-color: var(--nord1);

	--dialog-box__header---color: var(--nord6);
	--dialog-box__header---background-color: var(--nord2);
	--dialog-box__body---color: var(--nord5);
	--dialog-box__body---background-color: var(--nord1);
	--dialog-box__footer---color: var(--nord6);
	--dialog-box__footer---background-color: var(--nord2);

	/* Fields */
	--general__field__input---color: var(--general---color);
	--general__field__input---background-color: var(--general---background-color);
	--search-drop-down__arrow-icon-container---background-color: var(--general---background-color--semi-transparent);
	--general__field__input--focus---border-color: var(--nord8);

	--input---border-color: var(--nord3);
	--input--focused---border-color: var(--nord3);

	--label---color: var(--nord4);
	--label__optional--editing---color: #ccc;
	--label__optional---color: #777;

	--field-toolbar---color: var(--general---color);
	--field-toolbar---background-color: var(--nord3);
	--field-toolbar--hover-focus---background-color: var(--nord1);
}

body {
	font-family: sans-serif;
}

main {
	padding: 1rem 5vw 5rem;
	min-height: 100vh;
}

code {
	font-family: "Courier New", Courier, monospace;
	font-size: 14px;
}

.icon-button {
	color: var(--nord3);
}

.icon-button:hover {
	background-color: var(--nord1);
	color: var(--nord6);
	cursor: pointer;
}

.icon-button:disabled {
	fill: transparent;
	stroke: var(--nord3);
}

.icon-button:disabled:hover {
	cursor: default;
	background-color: unset;
}

.model-list {
	width: 100%;
}

.model-list-item {
	margin: 0.5rem 0;
}

.model-list-item code {
	padding: 0.25rem 0.5rem;
}

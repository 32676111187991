.edge-list {
	width: 100%;
}

.edge-list-item {
	margin: 0.5rem 0;
}

.edge-list-item code {
	padding: 0.25rem 0.5rem;
}

.field-set-column {
	margin: 1rem 0;
}

.collapsible-field-set > div:nth-child(2) {
	border-left: 1px solid var(--nord3);
	padding-left: 0.5rem;
	padding-bottom: 0.5rem;
}

.row-with-bottom-margin {
	margin-bottom: 0.5rem;
}

.form-submit-buttons {
	margin-top: 2rem;
	margin-bottom: 0.75rem;
}

.error-box {
	background-color: var(--danger-color);
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--danger-dark-color);
	border-radius: var(--default---border-radius);
}
